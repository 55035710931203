$(document).foundation();
//Підсвічування ссилок 
var url = window.location.href;
// Mai menu
$('#topMenu a[href="'+ url +'"]').addClass('highlight');
//aside menu
$('.vertical a[href="'+ url +'"]').addClass('category-highlight');
//aside submenu
  if ($('.vertical a[href="'+ url +'"]').closest("ul").hasClass('nested')) {
    $('.vertical a[href="'+ url +'"]').closest("ul").prev().addClass('category-highlight');
    $('.vertical a[href="'+ url +'"]').closest("ul").addClass('active-submenu');
    $('h3').removeClass('category-highlight');
}
// Керує стрілочками в меню категорій
  (function( $, window, document, undefined )
{
  $.fn.doubleTapToGo = function( params )
  {
    if( !( 'ontouchstart' in window ) &&
      !navigator.msMaxTouchPoints &&
      !navigator.userAgent.toLowerCase().match( /windows phone os 7/i ) ) return false;

    this.each( function()
    {
      var curItem = false;

      $( this ).on( 'click', function( e )
      {
        var item = $( this );
        if( item[ 0 ] != curItem[ 0 ] )
        {
          e.preventDefault();
          curItem = item;
        }
      });

      $( document ).on( 'click touchstart MSPointerDown', function( e )
      {
        var resetItem = true,
          parents   = $( e.target ).parents();

        for( var i = 0; i < parents.length; i++ )
          if( parents[ i ] == curItem[ 0 ] )
            resetItem = false;

        if( resetItem )
          curItem = false;
      });
    });
    return this;
  };
})( jQuery, window, document );
$( '.vertical.menu li:has(ul)' ).doubleTapToGo();
  
// Мягка прокрутка 
 $(function() {
  $('a[href*=#]:not([href=#])').click(function() {
    if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
      var target = $(this.hash);
      target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
      if (target.length) {
        $('html,body').animate({
          scrollTop: target.offset().top
        }, 1000);
        return false;
      }
    }
  });
});

